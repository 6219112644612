class AccountDetails {
    constructor() {
        this.lastOpenAccount = null;
        this.ready();
    }

    ready() {
        this.attachEventHandlers();
        this.setupHTMXListeners();
        this.attachAccountDeleteHandlers(); // Attach delete handlers initially
    }

    setupHTMXListeners() {
        $(document).off('htmx:afterOnLoad', this.handleHTMXLoad.bind(this));
        $(document).on('htmx:afterOnLoad', this.handleHTMXLoad.bind(this));
        $(document).off('htmx:afterSwap', this.handleHTMXSwap.bind(this));
        $(document).on('htmx:afterSwap', this.handleHTMXSwap.bind(this));
    }

    handleHTMXLoad(event) {
        if (event.detail.target.id === 'account-container') {
            $('.account-wrapper').addClass('account-wrapper--open');
            setTimeout(() => {
                this.attachAccountCloseHandlers();
                this.setFocusOnCloseButton();
                this.attachAccountDeleteHandlers(); // Attach delete handlers after HTMX load
            }, 100);
        }
        this.attachEventHandlers(); // Reattach event handlers after HTMX load
    }

    handleHTMXSwap(event) {
        if (event.detail.target.id === 'account-list') {
            this.attachAccountDeleteHandlers(); // Reattach delete handlers after HTMX swap
        }
    }

    attachEventHandlers() {
        const self = this;

        console.log('Attaching event handlers to .account__open elements');

        $('.account__open')
            .off('click keypress')  // Remove any previously attached handlers
            .on('click', function () {
                self.lastOpenAccount = $(this);
                self.openAccount();
            })
            .on('keypress', function (event) {
                if (event.key === 'Enter') {
                    event.preventDefault();  // Prevent default behavior
                    $(this).trigger('click');
                }
            });

        this.attachAccountCloseHandlers();
    }

    attachAccountCloseHandlers() {
        const self = this;
        $('.account__close')
            .off('click keypress')  // Remove any previously attached handlers
            .on('click', function () {
                self.closeAccount();
            })
            .on('keypress', function (event) {
                if (event.key === 'Enter') {
                    $(this).click();
                }
            });
    }

    attachAccountDeleteHandlers() {
        const self = this;
        $('.account__delete')
            .off('click keypress')
            .on('click', function (event) {
                event.stopPropagation(); // Ensure the event does not bubble up
                self.openConfirmation(event, $(this));
            })
            .on('keypress', function (event) {
                if (event.key === 'Enter') {
                    event.preventDefault();  // Prevent default behavior
                    self.openConfirmation(event, $(this));
                }
            });

        $('.account__delete--close')
            .off('click keypress')
            .on('click', function (event) {
                event.stopPropagation(); // Ensure the event does not bubble up
                self.closeConfirmation(event, $(this));
            })
            .on('keypress', function (event) {
                if (event.key === 'Enter') {
                    event.preventDefault();  // Prevent default behavior
                    self.closeConfirmation(event, $(this));
                }
            });

        $('.account__confirmation')
            .off('click keypress')
            .on('click', function (event) {
                event.stopPropagation();
            });
    }

    openConfirmation(event, element) {
        event.stopPropagation();
        element.next().addClass('account__confirmation--active');
    }

    closeConfirmation(event, element) {
        event.stopPropagation();
        element.parent().removeClass('account__confirmation--active');
    }

    openAccount() {
        // Wait for the HTMX request to complete and DOM to update
        $(document).on('htmx:afterSwap', (event) => {
            if (event.detail.target.id === 'account-container') {
                setTimeout(() => {
                    this.attachAccountCloseHandlers();
                    this.attachAccountDeleteHandlers(); // Attach delete handlers after HTMX swap
                    this.setFocusOnCloseButton();
                }, 100);
            }
        });
    }

    setFocusOnCloseButton() {
        const closeButton = $('.account__close');
        if (closeButton.length > 0) {
            closeButton.focus();
        }
    }

    closeAccount() {
        $('.account-wrapper').removeClass('account-wrapper--open');
        $('.account-container').empty();
        if (this.lastOpenAccount) {
            this.lastOpenAccount.focus();
        }
    }
}

export default new AccountDetails();









