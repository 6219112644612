// This is the scss entry file
// We can import other JS file as we like
import Jumbotron from "../components/jumbotron";
import "../styles/index.scss";
import AccountDetails from "../components/account-details";

$(document).ready(function () {

    // Find elements and initialize
    $(Jumbotron.selector()).each(function () {
        new Jumbotron(this);
    });

    AccountDetails.ready();
});


